<template>
   <section>
      <div class="">
         <b-modal
            id="modal-createdToEdit-prospection"
            cancel-variant="outline-secondary"
            ok-title="Créer"
            cancel-title="Annuler"
            centered
            :title="
               isUpdate
                  ? 'Modifier la prospection'
                  : 'Ajouter un nouvel prospection'
            "
            @ok="prospectionCreatedToEdit"
         >
            <OnlyForm
               label="Libelle de la prospection"
               :require_form="true"
               page="prospection"
               :modelValue="dataInput.libelle"
               @update:modelValue="dataInput.libelle = $event"
               name="libelle"
               placeholder="Recherche de clients stratégique"
               :validate="validateProspection"
               :error="errorInput"
            />

            <OnlyDateForm
               label="Date de début"
               :modelValue="dataInput.date_debut"
               @update:modelValue="dataInput.date_debut = $event"
               :require_form="true"
               page="prospection"
               name="date_debut"
               placeholder="01/01/1998"
               :validate="validateProspection"
               :error="errorInput"
            />

            <OnlyDateForm
               label="Date de fin"
               :modelValue="dataInput.date_fin"
               @update:modelValue="dataInput.date_fin = $event"
               :require_form="true"
               page="prospection"
               name="date_fin"
               placeholder="01/01/1998"
               :validate="validateProspection"
               :error="errorInput"
            />

            <OnlySelectForm
               label="Domaine d'activité"
               :require_form="true"
               page="prospection"
               :modelValue="dataInput.domaines"
               @update:modelValue="dataInput.domaines = $event"
               name="domaine"
               :options="listDomaine"
               placeholder="Informatique/Tele-communication"
               :validate="validateProspection"
               :error="errorInput"
               :labelOptions="'libelle'"
               :multiple="true"
            />

            <b-form-group
               label="Motif de la prospection"
               label-for="register-description"
            >
               <b-form-textarea
                  id="textarea"
                  v-model="dataInput.description"
                  placeholder="Saisissez une description"
                  rows="3"
                  max-rows="6"
               ></b-form-textarea>
            </b-form-group>

            <template #modal-footer>
               <b-button
                  :disabled="state.loading === true ? true : false"
                  variant="primary"
                  @click.stop.prevent="prospectionCreatedToEdit"
               >
                  <span v-if="state.loading === false">
                     {{ isUpdate ? 'Modifier' : 'Ajouter' }}
                  </span>
                  <b-spinner
                     v-if="state.loading === true"
                     label="Spinning"
                  ></b-spinner>
               </b-button>
            </template>
         </b-modal>

         <b-card no-body class="">
            <b-row class="px-2 py-2">
               <!-- Per Page -->
               <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mt-1"
               >
                  <label>Entrées</label>
                  <v-select
                     v-model="state.perPage"
                     :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                     :options="state.perPageOptions"
                     :clearable="false"
                     class="per-page-selector d-inline-block ml-50 mr-1"
                  />

                  <b-button
                     variant="primary"
                     v-b-modal.modal-createdToEdit-prospection
                     @click="isUpdate = false"
                  >
                     <div class="flex gap-2">
                        <feather-icon icon="PlusIcon" class="mx-auto" />
                        <span>Créer une prospection</span>
                     </div>
                  </b-button>
               </b-col>

               <!-- Search -->
               <b-col cols="12" md="4" class="mt-1">
                  <div class="d-flex align-items-center justify-content-end">
                     <b-input-group class="input-group-merge">
                        <b-form-input
                           v-model="state.filtreProspection"
                           class="d-inline-block mr-1"
                           placeholder="Rechercher par : Nom, role, contact"
                        />
                     </b-input-group>
                  </div>
               </b-col>
            </b-row>

            <q-loader-table
               :success="state.success"
               :empty="state.empty"
               :warring="state.warring"
            />

            <b-table
               hover
               responsive
               primary-key="id"
               :per-page="state.perPage"
               :current-page="state.currentPage"
               :items="Prospection"
               :fields="tableColumns"
               :filter="state.filtreProspection"
               show-empty
               empty-text=""
               class="bg-white"
               v-if="state.success === true"
            >
               <!-- new butt -->

               <template #cell(actions)="data">
                  <b-button
                     v-b-modal.modal-createdToEdit-prospection
                     v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                     variant="success"
                     class="btn-icon rounded-circle mr-1"
                     @click="getCurrentDataToEdit(data.item)"
                  >
                     <feather-icon icon="Edit3Icon" />
                  </b-button>

                  <b-button
                     v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                     variant="danger"
                     class="btn-icon rounded-circle"
                     @click="deleteProspection(data.item.id)"
                  >
                     <feather-icon icon="Trash2Icon" />
                  </b-button>

                  <b-button
                     v-b-modal.modal-send-prospection
                     v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                     variant="success"
                     class="btn-icon rounded-circle ml-1"
                     @click="sendProspectionMessageID(data.item.id)"
                  >
                     <feather-icon icon="SendIcon" />
                  </b-button>
               </template>
            </b-table>

            <!-- Les boutons de pagination -->
            <div class="mx-2 mb-2 pb-1">
               <b-row>
                  <b-col
                     cols="12"
                     sm="6"
                     class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                     <span class="text-muted"></span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                     cols="12"
                     sm="6"
                     class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >
                     <b-pagination
                        v-model="state.currentPage"
                        :total-rows="Prospection.length"
                        :per-page="state.perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                     >
                        <template #prev-text>
                           <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                           <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                     </b-pagination>
                  </b-col>
               </b-row>
            </div>
         </b-card>
      </div>

      <b-modal
         id="modal-send-prospection"
         ok-only
         ok-title="Envoyer"
         centered
         title="Prospection"
         @ok="sendProspectionMessage"
      >
         <div class="row">
            <div class="col-lg-12">
               <label for="">Message</label>
               <b-form-textarea
                  id="textarea"
                  v-model="inputMessage"
                  placeholder="Envoyez un message à vos prospects"
                  rows="5"
                  max-rows="6"
               >
               </b-form-textarea>
            </div>
         </div>

         <template #modal-footer>
            <b-button
               :disabled="state.loading === true ? true : false"
               variant="primary"
               @click.stop.prevent="sendProspectionMessage"
            >
               <span v-if="state.loading === false">Envoyer</span>
               <b-spinner
                  v-if="state.loading === true"
                  label="Spinning"
               ></b-spinner>
            </b-button>
         </template>
      </b-modal>

      {{ __NEXT }}
   </section>
</template>

<script>
import {
   BRow,
   BCol,
   BModal,
   BTable,
   BPagination,
   BFormInput,
   BFormGroup,
   BButton,
   VBModal,
   BForm,
   BLink,
   BFormCheckbox,
   BInputGroup,
   BInputGroupAppend,
   BImg,
   BFormTextarea,
   BBadge,
   VBToggle,
   BCard,
   BSpinner,
} from 'bootstrap-vue';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import { required, email } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vSelect from 'vue-select';
import URL from '@/views/pages/request/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import QLoaderTable from '@/components/__partials/loaders/qLoaderTable.vue';
import axios from 'axios';
import flatPickr from 'vue-flatpickr-component';
import { computed, onMounted, reactive, ref } from '@vue/composition-api';
import OnlySelectForm from '@/components/forms/onlySelectForm.vue';
import OnlyForm from '@/components/forms/onlyForm.vue';
import OnlyDateForm from '@/components/forms/onlyDateForm.vue';
import { toast_sucess } from '@/utils/qToast';
import { async } from 'q';

export default {
   components: {
      QLoaderTable,
      BSpinner,
      ToastificationContent,
      flatPickr,
      vSelect,
      VBToggle,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BModal,
      BTable,
      BPagination,
      BCard,
      BFormGroup,
      VBModal,
      BForm,
      BImg,
      BLink,
      BForm,
      BFormCheckbox,
      BInputGroup,
      BFormTextarea,
      BInputGroupAppend,
      BBadge,
      // validations
      ValidationProvider,
      ValidationObserver,
      OnlySelectForm,
      OnlyForm,
      OnlyDateForm,
   },
   directives: {
      Ripple,
      'b-toggle': VBToggle,
   },
   mixins: [heightTransition],
   setup(props, { root }) {
      const state = reactive({
         loading: false,
         currentPage: 1,
         perPage: 30,
         perPageOptions: [30, 50, 100, 200, 500],
         filtreProspection: '',
         success: false,
         empty: false,
         warring: false,
         loading: false,
      });

      const input = reactive({
         model: {
            id: 0,
            libelle: '',
         },
         error: {
            message: '',
            path: '',
         },
      });

      const tableColumns = reactive([
         { key: 'code', label: 'code' },
         { key: 'libelle', label: 'libelle' },
         { key: 'actions' },
      ]);
      const Prospection = ref([]);
      const GIVE = ref(false);
      const dataInput = reactive({
         id: '',
         libelle: '',
         date_debut: '',
         date_fin: '',
         domaines: [],
         description: '',
      });
      const isUpdate = ref(false);
      const errorInput = reactive({
         path: '',
         message: '',
      });
      const listDomaine = ref([]);

      // To started page
      onMounted(() => {
         document.title = 'Prospections | Ediqia';
         getProspection();
         getDomaine();
      });

      const __NEXT = computed(() => {
         if (GIVE.value === true) {
            Prospection.value.length === 0
               ? ((state.empty = true), (state.success = false))
               : ((state.success = true), (state.empty = false));
         }
         return;
      });

      // Getting all Prospection
      const getProspection = async () => {
         try {
            const { data } = await axios.get(URL.PROSPECTION_LIST);
            if (data) {
               Prospection.value = data.prospections;
               Prospection.value.reverse();
               GIVE.value = true;
            }
         } catch (error) {
            console.log(error);
         }
      };

      // Get all domaine Activite
      const getDomaine = async () => {
         try {
            const { data } = await axios.get(URL.DOMAINE_PROSPECTION_LIST);
            if (data) {
               listDomaine.value = data.domaine;
            }
         } catch (error) {
            console.log(error);
         }
      };

      const inputMessage = ref('');
      const prospectionID = ref(0);
      const sendProspectionMessageID = async (id) => {
         prospectionID.value = id;
      };
      const sendProspectionMessage = async () => {
         state.loading = false;
         try {
            const { data } = await axios.post(URL.PROSPECTION_SEND_MESSAGE, {
               message: inputMessage.value,
               subject: '',
               prospection_id: prospectionID.value,
            });

            if (data) {
               toast_sucess(
                  root,
                  'success',
                  'top-right',
                  'Prospect creer avec succès.'
               );

               root.$bvModal.hide('modal-send-prospection');

               state.loading = false;
            }
         } catch (error) {
            console.log(error);
         }
      };

      const validateProspection = async (request) => {
         let error = null;
         let message = '';
         const fieldsNoEmpy = ['libelle', 'date_debut', 'date_fin', 'domaine'];
         const fieldsDate = ['date_debut', 'date_fin'];

         for (let i = 0; i < fieldsNoEmpy.length; i++) {
            const el = fieldsNoEmpy[i];

            message = 'Ce champs ne dois pas être vide.';
            if (request[el] === '') {
               errorInput.path = el;
               errorInput.message = message;
               return {
                  error: true,
               };
            } else if (
               request['date_debut'] !== '' ||
               request['date_fin'] !== ''
            ) {
               message = "Veillez s'il vous plait verifer les dates.";
               if (request['date_debut'] > request['date_fin']) {
                  errorInput.path = 'date_debut';
                  errorInput.message = message;
                  return {
                     error: true,
                  };
               }
            }
         }
         errorInput.path = '';
         errorInput.message = '';

         return { error: error };
      };

      // Add a new Prospection
      const addProspection = async () => {
         state.loading = true;
         try {
            dataInput.domaines = dataInput.domaines.map((el) => {
               return el.id;
            });

            const { error } = await validateProspection(dataInput);

            if (error) {
               state.loading = false;
               return errorInput;
            }

            const { data } = await axios.post(
               URL.PROSPECTION_CREATE,
               dataInput
            );
            if (data) {
               console.log(data);
               Prospection.value.unshift(data.prospection);
               root.$bvModal.hide('modal-createdToEdit-prospection');
               state.loading = false;
            }
         } catch (error) {
            state.loading = false;
            console.log(error);
         }
      };

      // get current data to update
      const getCurrentDataToEdit = (data) => {
         dataInput.domaines = [];

         for (let i = 0; i < listDomaine.value.length; i++) {
            const el = listDomaine.value[i];

            for (let j = 0; j < data.domaines.length; j++) {
               const jl = data.domaines[j];

               if (el.id === jl.id) {
                  dataInput.domaines.push(el);
               }
            }
         }

         isUpdate.value = true;
         input.model.id = data.id;
         dataInput.id = data.id;
         dataInput.libelle = data.libelle;
         dataInput.date_debut = data.date_debut;
         dataInput.date_fin = data.date_fin;
         dataInput.description = data.description;
      };
      // Edit a specific Prospection
      const editProspection = async () => {
         state.loading = true;
         try {
            const { error } = await validateProspection(dataInput);

            if (error) {
               state.loading = false;
               return errorInput;
            }

            dataInput.domaines = dataInput.domaines.map((el) => {
               return el.id;
            });

            const { data } = await axios.post(
               URL.PROSPECTION_UPDATE,
               dataInput
            );
            if (data) {
               console.log(data);

               for (let i = 0; i < Prospection.value.length; i++) {
                  const el = Prospection.value[i];

                  if (el.id === dataInput.id) {
                     const prospectionDataKey = Object.keys(el);
                     const registerDataKey = Object.keys(data.prospection);

                     for (let j = 0; j < prospectionDataKey.length; j++) {
                        const jl = prospectionDataKey[j];

                        if (jl === registerDataKey[j]) {
                           el[jl] = data.prospection[jl];
                        } else {
                           el[jl] = null;
                        }
                     }
                  }
               }

               root.$bvModal.hide('modal-createdToEdit-prospection');
               state.loading = false;
            }
         } catch (error) {
            state.loading = false;
            console.log(error);
         }
      };

      const prospectionCreatedToEdit = async () => {
         try {
            if (isUpdate.value) {
               await editProspection();
            } else {
               await addProspection();
            }
         } catch (error) {
            console.log(error);
         }
      };

      // Delete specific Prospection
      const deleteProspection = (id) => {
         root
            .$swal({
               title: 'Êtes vous sûr?',
               text: 'Cette prospection sera supprimé définitivement !',
               icon: 'warning',
               showCancelButton: true,
               confirmButtonText: 'Oui, supprimer-le!',
               customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
               },
               buttonsStyling: false,
            })
            .then(async (result) => {
               if (result.value) {
                  try {
                     const dataDelete = {
                        id: id,
                     };
                     const { data } = await axios.post(
                        URL.DOMAINE_PROSPECTION_DELETE,
                        dataDelete
                     );
                     if (data) {
                        Prospection.value = Prospection.value.filter((item) => {
                           return item.id !== id;
                        });
                        GIVE.value = true;
                        console.log(data);
                     }
                  } catch (error) {
                     console.log(error);
                  }
               }
            });
      };

      return {
         state,
         tableColumns,
         Prospection,
         inputMessage,
         addProspection,
         validateProspection,
         editProspection,
         getCurrentDataToEdit,
         deleteProspection,
         sendProspectionMessage,
         sendProspectionMessageID,
         prospectionCreatedToEdit,
         listDomaine,
         isUpdate,
         input,
         __NEXT,
         dataInput,
         errorInput,
      };
   },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
